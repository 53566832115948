window._farlo = window._farlo || {};
window._farlo = {
	gtm: window.gtm_id || null,
	body: document.querySelector('body'),
	w: window.innerWidth,
	helpers: {
		gtm: {
			getter(prop) {
				if (typeof (google_tag_manager) !== 'undefined' && window._farlo.gtm) {
					return google_tag_manager[ window._farlo.gtm ].dataLayer.get(prop);
				}
				return null;
			}
		},
		dl: {
			getter(look) {
				const prop = this.getObj(look);

				if (prop.length > 0) {
					return prop[ 0 ][ look ];
				}

				return false;
			},
			getObj(look) {
				const dataLayerName = 'dataLayer';

				if (window[ dataLayerName ]) {
					const getValueForObjectString = function(obj, key) {
						return key.split('.').reduce(function(o, x) {
							return (typeof o === 'undefined' || o === null) ? o : o[ x ];
						}, obj);
					};

					return window[ dataLayerName ].filter(function(obj) {
						const o = getValueForObjectString(obj, look);

						if ((typeof o !== 'undefined' || o !== null)) {
							return o;
						}
					});
				}

				return '';
			}
		},
		validators: {
			email(val) {
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

				return re.test(val);
			}
		},
		strings: {
			trim(str, len, toWord, suffix) {
				let newStr;

				if (str.length <= len) {
					return str;
				}

				newStr = str.substr(0, len);

				toWord = (typeof toWord === 'undefined' ? false : toWord);
				suffix = (typeof suffix === 'undefined' ? '...' : suffix);

				if (toWord) {
					newStr = newStr.substr(0, Math.min(newStr.length, newStr.lastIndexOf(' ')));
				}

				if (suffix !== false) {
					newStr += suffix;
				}

				return newStr;
			},
			sanitiseCssValue(value) {
				const float = parseFloat(value.replace(/[^-\d\.]/g, ''));

				return (float === NaN ? 0 : float);
			},
			toCurrency(val, fixed) {
				fixed = (typeof fixed === 'undefined' ? 2 : fixed);
				val = (typeof val === 'string' ? parseFloat(val) : val);

				return '&pound;' + val.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
			},
			toShortCurrency(val, fixed) {
				const sVal = val.toString().split('.');

				fixed = (typeof fixed === 'undefined' ? 2 : fixed);

				if (sVal.length > 1 && sVal[ 1 ] === '00') {
					fixed = 0;
				}

				val = (typeof val === 'string' ? parseFloat(val) : val);

				return '&pound;' + val.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
			},
			removeFromArray(arr) {
				const a = arguments;
				let L = a.length;
				let ax;
				let what;

				while (L > 1 && arr.length) {
					what = a[ --L ];

					while ((ax = arr.indexOf(what)) !== -1) {
						arr.splice(ax, 1);
					}
				}
				return arr;
			},
			escapeUnsafe(str) {
				if (str === '' || typeof str === 'undefined') {
					return '';
				}

				return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
			}
		},
		numeric: {
			round(value) {
				const converted = parseFloat(value);
				let decimal = (converted - parseInt(converted, 10));

				decimal = Math.round(decimal * 10);

				if (decimal === 5) {
					return (parseInt(converted, 10) + 0.5);
				}

				if ((decimal < 3) || (decimal > 7)) {
					return Math.round(converted);
				}
				return (parseInt(converted, 10) + 0.5);
			}
		},
		urls: {
			getHost() {
				let domain = location.hostname;
				const p = domain.split('.');
				const s = '_gd' + (new Date()).getTime();
				let i = 0;

				while (i < (p.length - 1) && document.cookie.indexOf(s + '=' + s) === -1) {
					domain = p.slice(-1 - (++ i)).join('.');
					document.cookie = s + '=' + s + ';domain=' + domain + ';';
				}

				document.cookie = s + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + domain + ';';

				return domain;
			},
			isHash(sel) {
				const href = (sel).attr('href').split('/');
				const path = href[ href.length - 1 ];
				const hash = path.split('#');

				return (hash.length > 1);
			},
			updateAndRedirect(key, value) {
				document.location.href = this.updateQuery(key, value);
			},
			updateQuery(key, value) {
				const vars = this.queryString();

				if (vars.length === 0 || (typeof (vars[ key ]) === 'undefined')) {
					vars[ key ] = value;
				}

				if (vars[ key ] === 'string') {
					vars[ key ] = value;
				}

				if (value === null) {
					delete vars[ key ];
				}

				return document.location.pathname + '?' + this.serialise(vars);
			},
			getQueryValue(key) {
				const vars = this.queryString();

				if ((typeof (vars[ key ]) !== 'undefined')) {
					return vars[ key ];
				}

				return null;
			},
			queryString() {
				const vars = [];
				let hash;
				const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

				if (hashes[ 0 ] === window.location.href) {
					return vars;
				}

				for (let i = 0; i < hashes.length; i += 1) {
					hash = hashes[ i ].split('=');
					vars[ hash[ 0 ] ] = hash[ 1 ];
				}

				return vars;
			},
			serialise(obj) {
				const str = [];
				let v;

				for (const p in obj) {
					if (obj.hasOwnProperty(p)) {
						v = decodeURIComponent(obj[ p ]);
						str.push(encodeURIComponent(p) + '=' + encodeURIComponent(v));
					}
				}
				return str.join('&');
			}

		},
		getSiblings(elem) {
			// Setup siblings array and get the first sibling
			const siblings = [];
			let sibling = elem.parentNode.firstChild;

			// Loop through each sibling and push to the array
			while (sibling) {
				if (sibling.nodeType === 1 && sibling !== elem) {
					siblings.push(sibling);
				}
				sibling = sibling.nextSibling;
			}

			return siblings;
		},
		wrapElement(el, wrapper, elClass) {
			el.parentNode.insertBefore(wrapper, el);
			wrapper.appendChild(el);
			elClass = elClass.split(',');
			wrapper.classList.add(...elClass);
		},
		iterationCopy(src) {
			const target = {};

			for (const prop in src) {
				if (src.hasOwnProperty(prop)) {
					target[ prop ] = src[ prop ];
				}
			}

			return target;
		}
	},
	mailto: false,
	browser: {
		init() {
			this.browser = this.searchString(this.dataBrowser) || 'Other';
			this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || 'Unknown';
		},
		searchString(data) {
			let dataString;

			for (let i = 0; i < data.length; i += 1) {
				dataString = data[ i ].string;
				this.versionSearchString = data[ i ].subString;

				if (dataString.indexOf(data[ i ].subString) !== -1) {
					return data[ i ].identity;
				}
			}
		},
		searchVersion(dataString) {
			const index = dataString.indexOf(this.versionSearchString);

			if (index === -1) {
				return;
			}

			const rv = dataString.indexOf('rv:');

			if (this.versionSearchString === 'Trident' && rv !== -1) {
				return parseFloat(dataString.substring(rv + 3));
			}
			return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
		},
		dataBrowser: [
			{ string: navigator.userAgent, subString: 'Edge', identity: 'MS Edge' },
			{ string: navigator.userAgent, subString: 'MSIE', identity: 'Explorer' },
			{ string: navigator.userAgent, subString: 'Trident', identity: 'Explorer' },
			{ string: navigator.userAgent, subString: 'Firefox', identity: 'Firefox' },
			{ string: navigator.userAgent, subString: 'Opera', identity: 'Opera' },
			{ string: navigator.userAgent, subString: 'OPR', identity: 'Opera' },
			{ string: navigator.userAgent, subString: 'Chrome', identity: 'Chrome' },
			{ string: navigator.userAgent, subString: 'Safari', identity: 'Safari' }
		]
	},
	logger: {
		error(_error) {
			console.error(_error);
		},
		warn(_warn) {
			console.warn(_warn);
		},
		log(_log) {
			console.log(_log);
		}
	},
	storage: {
		local: false,
		session: false,
		cookies: false,
		cookie: {
			defaults: {
				'time': 432E3,
				'path': '/',
				'domain': '',
				'SameSite': 'None',
				'Secure': 'True'
			},
			handle(name, value, time, path, domain, SameSite, Secure) {
				const cpath = '; path=' + (path ? path : window._farlo.storage.cookie.defaults.path);
				const cdomain = '; domain=' +  (domain ? domain : window._farlo.storage.cookie.defaults.domain);
				let expires = '';

				if (typeof name === 'undefined') {
					return false;
				}

				if (typeof value === 'undefined') {
					if (new RegExp('(?:^|;\\s*)' + escape(name).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(document.cookie)) {
						return unescape(document.cookie.replace(new RegExp('(?:^|.*;\\s*)' + escape(name).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*'), '$1'));
					}
					return false;
				}

				SameSite = '; SameSite=None';
				Secure = '; Secure=True';

				switch (typeof time) {
					case 'boolean':
						const t = new Date;
						t.setDate(t.getDate() - 1);
						expires = '; expires=' + t.toGMTString();
						break;
					case 'number':
						expires = '; max-age=' + time;
						break;
					case 'string':
						expires = '; expires=' + time;
						break;
					case 'object':
						return time.hasOwnProperty('toGMTString') && (expires = '; expires=' + time.toGMTString());
					default :
						expires = '; max-age=' + window._farlo.storage.cookie.defaults.time;
				}

				document.cookie = name + '=' + value + expires + cpath + cdomain + SameSite + Secure;
			},
			get(name) {
				try {
					const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));

					if (match) {
						return match[ 2 ];
					}
					window._farlo.logger.warn('Cookie ' + name + ' not found');

					return null;
				} catch (ex) {
					window._farlo.logger.error('Error retrieving cookie: ' + name);
					window._farlo.logger.error(ex.message);
				}
			},
			'delete'(name) {
				window._farlo.storage.cookie.handle(name, 0, new Date('1970-01-01'));
			}
		},
		checker() {
			const mod = 'farlo';
			let host;

			try {
				localStorage.setItem(mod, mod);
				localStorage.removeItem(mod);

				window._farlo.storage.local = true;
			} catch (ex) {
				window._farlo.storage.local = false;
				window._farlo.logger.warn('Local Storage unavailable.');
				window._farlo.logger.error(ex.message);
			}
			try {
				sessionStorage.setItem(mod, mod);
				sessionStorage.removeItem(mod);

				window._farlo.storage.session = true;
			} catch (ex) {
				window._farlo.storage.session = false;
				window._farlo.logger.warn('Session Storage unavailable.');
				window._farlo.logger.error(ex.message);
			}
			try {
				host = window._farlo.helpers.urls.getHost();

				window._farlo.storage.cookie.defaults.domain = host !== 'localhost' ? '.' + host : host;
				window._farlo.storage.cookie.handle(mod, mod);
				window._farlo.storage.cookie.delete(mod);
				window._farlo.storage.cookies = true;
			} catch (ex) {
				window._farlo.storage.cookies = false;
				window._farlo.logger.warn('Cookie Storage unavailable.');
				window._farlo.logger.error(ex.message);
			}
		},
		get(key, type, callback) {
			let obj;
			let storedVal;

			type = (typeof type === 'undefined' ? 'local' : type);

			if (!window._farlo.storage[ type ]) {
				return false;
			}

			const w = (type === 'cookie' ? window._farlo.storage.cookie.get(key) : window[ type + 'Storage' ].getItem(key));

			if (w != null) {
				storedVal = JSON.parse(atob(w));

				if (Array.isArray(storedVal) && storedVal.length === 1) {
					storedVal = storedVal[ 0 ];
				}

				if (typeof callback !== 'undefined') {
					obj = {};

					obj[ key ] = storedVal;
					callback(obj);
				} else {
					return storedVal;
				}
			}

			return null;
		},
		set(key, v, type, callback) {
			let obj;
			const storeVal = [];

			type = (typeof type === 'undefined' ? 'local' : type);

			if (!window._farlo.storage[ type ]) {
				return false;
			}

			storeVal.push(v);
			const strVal = JSON.stringify(storeVal);
			const encStrVal = btoa(strVal);

			if (type === 'cookie') {
				window._farlo.storage.cookie.handle(key, encStrVal);
			} else {
				window[ type + 'Storage' ].setItem(key, encStrVal);
			}

			if (typeof callback !== 'undefined') {
				obj = {};

				obj[ key ] = storeVal;
				callback(obj);
			} else {
				return storeVal;
			}
			return null;
		},
		addTo(key, v, type, callback) {
			let storedVal;
			let obj;

			type = (typeof type === 'undefined' ? 'local' : type);

			if (!window._farlo.storage[ type ]) {
				return false;
			}

			storedVal = window._farlo.storage.get(key, type);

			if (storedVal === null) {
				storedVal = [];
			}

			if (!Array.isArray(storedVal)) {
				storedVal = [storedVal];
			}

			storedVal.push(v);
			const strVal = JSON.stringify(storedVal);
			const encStrVal = btoa(strVal);

			if (type === 'cookie') {
				window._farlo.storage.cookie.handle(key, encStrVal);
			} else {
				window[ type + 'Storage' ].setItem(key, encStrVal);
			}

			if (typeof callback !== 'undefined') {
				obj = {};

				obj[ key ] = storedVal;
				callback(obj);
			} else {
				return storedVal;
			}

			return null;
		},
		removeFrom(key, v, type, callback) {
			let w;
			let obj;
			let strVal;
			let encStrVal;

			type = (typeof type === 'undefined' ? 'local' : type);

			if (!window._farlo.storage[ type ]) {
				return false;
			}

			w = window._farlo.storage.get(key, type);

			if (w !== null) {
				if (!Array.isArray(w)) {
					w = [w];
				}

				w = window._farlo.helpers.strings.removeFromArray(w, v);

				strVal = JSON.stringify(w);
				encStrVal = btoa(strVal);

				if (type === 'cookie') {
					window._farlo.storage.cookie.handle(key, encStrVal);
				} else {
					window[ type + 'Storage' ].setItem(key, encStrVal);
				}

				if (typeof callback !== 'undefined') {
					obj = {};

					obj[ key ] = w;
					callback(obj);
				}
				return w;
			}
			window._farlo.logger.warn('Unable to remove ' + v + ' from ' + type + ' storage item: ' + key);

			return null;
		},
		'delete'(key, type) {
			type = (typeof type === 'undefined' ? 'local' : type);

			if (!window._farlo.storage[ type ]) {
				return false;
			}

			try {
				if (type === 'cookie') {
					window._farlo.storage.cookie.delete(key);
				} else {
					window[ type + 'Storage' ].removeItem(key);
				}
			} catch (ex) {
				window._farlo.logger.warn('Unable to remove ' + type + ' storage item: ' + key);

				return false;
			}

			return true;
		}
	},
	events: {
		push(category, action, label, value, nonInteraction) {
			const dataLayer = window.dataLayer || [];

			value = (typeof value === 'undefined' ? '' : value);
			nonInteraction = (typeof nonInteraction === 'undefined' ? false : nonInteraction);

			const data = {
				'event': 'GAEvent',
				'eventCategory': category,
				'eventAction': action,
				'eventLabel': label,
				'eventValue': value,
				'nonInteractionHit': nonInteraction
			};

			dataLayer.push(data);
		},
		productClick(product) {
			const dataLayer = window.dataLayer || [];

			dataLayer.push({
				'event': 'productClick',
				'ecommerce': {
					'click': {
						'actionField': {
							'list': product.list
						},
						'products': [{
							'id': product.id,
							'name': product.name,
							'price': product.price,
							'brand': product.brand,
							'category': product.category,
							'position': product.position
						}]
					}
				}
			});
		},
		addToCart(product) {
			const dataLayer = window.dataLayer || [];

			dataLayer.push({
				'event': 'addToCart',
				'ecommerce': {
					'currencyCode': 'GBP',
					'add': {
						'products': [
							product
						]
					}
				}
			});
		},
		removeFromCart(product) {
			const dataLayer = window.dataLayer || [];

			dataLayer.push({
				'event': 'removeFromCart',
				'ecommerce': {
					'currencyCode': 'GBP',
					'remove': {
						'products': [
							product
						]
					}
				}
			});
		}
	}
};

const _farloUi = {
	init() {
		// Happens first
		window._farlo.storage.checker();

		document.querySelectorAll('[farlo-ui]').forEach(function(sel) {
			switch (sel.getAttribute('farlo-ui')) {
				case 'reviewStyle':
					_farloUi.tools.reviewStyle(sel);
					break;
				case 'simple-signup':
					_farloUi.ajax.signup.create(sel);
					break;
				case 'toggler':
					_farloUi.tools.toggler(sel);
					break;
				case 'event':
					_farloUi.tools.eventPusher(sel);
					break;
			}
		});
		document.querySelectorAll('[farlo-event]').forEach(function(sel) {
			this.tools.eventPusher(sel);
		});

		// Happens last
		this.handlers.init();
	},
	handlers: {
		scroll: [],
		resize: [],
		load: [],
		init() {
			document.addEventListener('scroll', function() {
				_farloUi.handlers.scroll.forEach(function(fn) {
					fn.call(this);
				});
			});
			window.addEventListener('resize', function() {
				_farloUi.handlers.resize.forEach(function(fn) {
					fn.call(this);
				});
			});
			window.addEventListener('DOMContentLoaded', function() {
				_farloUi.handlers.load.forEach(function(fn) {
					fn.call(this);
				});
			});
		}
	},
	consent: {
		close(obj) {
			try {
				const _sel = document.querySelector('#' + Object.keys(obj)[ 0 ]);
				_sel.classList.add('farlo-anim-slide-down');
			} catch (ex) {
				window._farlo.logger.error(ex.message);
			}
		}
	},
	ajax: {
		request(data, callback, _sel) {
			fetch(farloAjaxHandler.ajaxurl, {
				method: "POST",
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}).then(response => response.json())
			.then(responseData => {
				if (typeof callback !== 'undefined') {
					callback(responseData, _sel);
				}
			}).catch(error => console.error('Error:', error));
		},
		signup: {
			create(_sel) {
				const simpleSignup = function() {
					const email = _sel.querySelector('[data-crm-email]').value;

					if (email && window._farlo.helpers.validators.email(email)) {
						const _data = {
							action: 'simple_signup',
							nonce: farloAjaxHandler.nonce,
							email
						};
						_farloUi.ajax.request(_data, _farloUi.ajax.signup.response, _sel);
					} else {
						_farloUi.tools.toast.create('Please enter a valid email address.', false);
						_sel.classList.add('farlo-error');
					}
				};
				_sel.querySelector('form').addEventListener('submit', function(event, frm) {
					event.preventDefault();
					simpleSignup();
				});
			},
			response(response, _sel) {
				if (response.data.success) {
					_sel.querySelector('form').classList.add('farlo-slide-up-hide');
				} else {
					_sel.querySelector('form').classList.add('farlo-form-error');
					if (response.data.message === '') {
						response.data.message = 'Sorry, we were unable to subscribe you.';
					}
				}

				_sel.innerHTML += '<p>' + response.data.message + '</p>';
			}

		}
	},
	tools: {
		eventPusher(_sel) {
			_sel.addEventListener('click', function(e) {
				if (e.href) {
					e.preventDefault();
				}

				window._farlo.events.push(_sel.dataset.c, _sel.dataset.a, _sel.dataset.l || '', _sel.dataset.v || '');

				if (e.href) {
					document.location.href = e.href;
				}
			});
		},
		toggler(_sel) {
			_sel.addEventListener('click', function() {
				document.querySelector(_sel.dataset.target).classList.toggle(_sel.dataset.toggle);
			});
		},
		reviewStyle(_sel) {
			switch (_sel.dataset.style) {
				case 'award':
					_sel.innerHtml = "<i class='fa fa-trophy'></i>";
					break;

				case 'star':
					const _starVal = _sel.dataset.stars;
					try {
						const _f = parseFloat(_starVal);
						if (_f) {
							const _w = (_f * 20).toFixed(2);
							_sel.innerHTML = "<div class='farlo-review-stars' style='width:" + _w + "%'><i class='fa fa-star'></i><i class='fa fa-star'></i><i class='fa fa-star'></i><i class='fa fa-star'></i><i class='fa fa-star'></i></div>";
						}
					} catch (ex) {
					}
					break;
			}
		},
		toast: {
			createDyanamic(data) {
				_farloUi.tools.toast.create(data.message, false, 5000);
			},
			create(msg, retain, forceTimeout) {
				const elem = document.createElement('div');
				elem.classList.add('toast-item');

				forceTimeout = (typeof forceTimeout === 'undefined' ? 2000 : forceTimeout);
				const toasts = document.querySelectorAll('.toast-item').length;

				const t = (toasts > 0 ? document.querySelector('.toast-item') : elem);

				if (toasts > 0) {
					t.innerHTML = window._farlo.helpers.strings.escapeUnsafe(msg);
				} else {
					t.append(window._farlo.helpers.strings.escapeUnsafe(msg));
					document.getElementsByTagName('body')[ 0 ].appendChild(t);
				}
				setTimeout(function() {
					t.classList.add('toast-visible');
				}, 100);

				if (!retain || forceTimeout) {
					setTimeout(function() {
						Promise.resolve()
							.then(() => new Promise((resolve) => {
								t.style.animation = 'fadeOut 500ms forwards';
								t.addEventListener('animationend', resolve);
							}))
							.then(() => t.remove());
					}, forceTimeout);
				}
			}
		}
	}
};

_farloUi.init();
